/// <reference lib="dom" />

import { isEmpty, isEqual, isString, uniq } from 'lodash';
import { basename, extname } from 'path';
import { FileNameAndExtension } from '../dto/genericDto';

/**
 * Stubbing out the Vue.set type, to allow for extracting logic out that is reliant on this functionality.
 */
export type VueSet = (v: VueSetValue) => void;
export type VueSetValue = {
  object: Record<string | number, any>;
  key: string | number;
  value: any;
};

/**
 * Useful for marking 1 or more properties as optional in a given type.
 */
export type PartialBy<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

export function parseOrNull<T>(value: string | null | undefined): T | null {
  if (!value) {
    return null;
  }

  let result: T | null;
  try {
    result = JSON.parse(value);
  } catch (err) {
    console.error(`Error while parsing body: ${err}`);
    return null;
  }
  return result;
}

export function nonEmptyOrUndefined<T>(value: T) {
  return isEmpty(value) ? undefined : value;
}

export const isArrayOfString = (x: unknown): x is string[] => Array.isArray(x) && x.every(element => typeof element === 'string');

export const isObject = (obj: unknown): obj is Record<string, unknown> => {
  if (!obj) return false;
  return typeof obj === 'object';
};

/**
 * Checks whether a given value string|number is member of an enum
 * @param e Type of the Enum to test
 */
export const isEnum = <T>(e: T): ((token: unknown) => token is T[keyof T]) => {
  const keys = Object.keys(e).filter(k => {
    return !/^\d/.test(k);
  });
  const values = keys.map(k => {
    return (e as any)[k];
  });
  return (token: unknown): token is T[keyof T] => {
    return values.includes(token);
  };
};

export const encodeBase64 = (text: string) => {
  return Buffer.from(text).toString('base64');
};

export function decodeBase64(base64Encoded: string) {
  return Buffer.from(base64Encoded, 'base64').toString();
}

export const sleep = (ms: number) =>
  // eslint-disable-next-line @typescript-eslint/no-implied-eval
  new Promise(resolve => void setTimeout(resolve, ms));

/**
 * The purpose of this function is to insert breaking points in
 * long running functions so that the event loop can be processed
 * instead of being blocked for too long. By sleeping 0 ms, we are
 * effectively deferring the subsequent operations to the next
 * iteration of the event loop.
 */
export async function defer(): Promise<void> {
  await sleep(0);
}

export function padLeft(string: string, pad: string, length: number) {
  return (new Array(length + 1).join(pad) + string).slice(-length);
}

function hexa_to_decimal(digit: string) {
  const digits = '0123456789abcdef';
  return digits.indexOf(digit);
}

/**
 * Converts a #rrggbbaa hexa string to rgba(#,#,#,#), returns undefined on
 * unexpected data.
 *
 * @param hexa the hexa string to convert
 */
export const getRgbaFromHex = (hexa: string): string | undefined => {
  if (!hexa || hexa.length !== 9 || !hexa.startsWith('#')) return undefined;

  hexa = hexa.toLowerCase();
  const red = hexa_to_decimal(hexa.charAt(1)) * 16 + hexa_to_decimal(hexa.charAt(2));
  const green = hexa_to_decimal(hexa.charAt(3)) * 16 + hexa_to_decimal(hexa.charAt(4));
  const blue = hexa_to_decimal(hexa.charAt(5)) * 16 + hexa_to_decimal(hexa.charAt(6));

  // Alpha needs to be a value between 0 and 1 in the CSS world.
  const alpha = (hexa_to_decimal(hexa.charAt(7)) * 16 + hexa_to_decimal(hexa.charAt(8))) / 255;

  return `rgba(${red},${green},${blue},${alpha})`;
};

export const entries = Object.entries as <T>(o: T) => [Extract<keyof T, string>, T[keyof T]][];

export const isValidGUID = (guid?: string): boolean => {
  if (!guid) return false;
  const GUIDValidatorRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
  return GUIDValidatorRegex.test(guid);
};

export const isProxyFile = (filename: string): boolean => {
  if (!filename) return false;
  const ValidatorRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}-proxy.mp4$/;
  return ValidatorRegex.test(filename);
};

// Splits a string at a given index and returns the two parts in an array
export const splitAt =
  (index: number) =>
  (x: string): Array<string> =>
    index === -1 ? [x] : [x.slice(0, index), x.slice(index + 1)];

/**
 * Extracts itemId from a fileName
 * The fileName format should be like: some-file-specific-info_itemId.ext
 * @param fileName - FileName to parse, may contain full directory path
 */
export const getItemIdFromFileName = (fileName: string) => {
  if (!fileName) return;
  const fileBaseName = basename(fileName);
  let fileId = splitAt(fileBaseName.lastIndexOf('.'))(fileBaseName)[0];
  const indexOfDelimiter = fileId.lastIndexOf('_');
  if (indexOfDelimiter > -1) {
    fileId = splitAt(indexOfDelimiter)(fileId)[1];
  }
  return fileId;
};

export const getFileNameFromMangledWithId = (fileName: string) => {
  const ext = extname(fileName);
  const fileBaseName = basename(fileName, ext);
  const indexOfDelimiter = fileBaseName.lastIndexOf('_');
  let name = fileBaseName;
  if (indexOfDelimiter > -1) {
    [name] = splitAt(indexOfDelimiter)(fileBaseName);
  }
  return `${name}${ext}`;
};

// Splits file name and extension and injects an ID value with underscore as a delimiter
export const mangleFileNameWithId = (fileName: string, id: string, excludeOriginalName: boolean = false): string => {
  const [filePrefix, ext] = splitAt(fileName.lastIndexOf('.'))(fileName);
  const mangledFileName = excludeOriginalName ? `${id}.${ext}` : `${filePrefix}_${id}.${ext}`;

  return mangledFileName;
};

export const getNormalizedHexString = (s: string | undefined) => {
  if (!isHexString(s)) {
    return s;
  }

  return s!.substr(2).toUpperCase();
};

export const isHexString = (s: string | undefined) => {
  if (typeof s !== 'string') {
    return false;
  }

  if (!s.startsWith('0x') && !s.startsWith('0X')) {
    return false;
  }

  return true;
};

export const isAlphaNumeric = (s: string | undefined) => {
  return typeof s === 'string' && /^[0-9A-Za-z]+$/i.test(s);
};

const escapeRegExp = (str: string) => {
  return str.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
};

export const replaceAll = (str: string, find: string, replace: string) => {
  // we need to use the function syntax to get around issues with $$ becoming $ while replacing.
  return str.replace(new RegExp(escapeRegExp(find), 'g'), () => replace);
};

export const getPropertiesWithDifferentValues = <Properties>(oldObject: Properties, newObject: Properties): (keyof Properties)[] => {
  const oldKeys = Object.keys(oldObject) as Array<keyof Properties>;
  const newKeys = Object.keys(newObject) as Array<keyof Properties>;
  const uniqueProperties = new Set<keyof Properties>([...oldKeys, ...newKeys]);
  const propertiesWithDifferentValues = [...uniqueProperties].filter(property => (!isEmpty(oldObject[property]) || !isEmpty(newObject[property])) && !isEqual(oldObject[property], newObject[property]));
  return propertiesWithDifferentValues;
};

// sets the result of the promise as the prop on the obj
export const setPropFromPromise = async (obj: any, prop: any, promise: Promise<any>) => {
  obj[prop] = await promise;
};

/**
 * This times async functions. Does not handle any exceptions.
 * @param asyncFn
 * @param asyncFnName
 */
export async function logTiming<T>(asyncFn: () => Promise<T>, asyncFnName: string): Promise<T> {
  const startTime: any = new Date();
  const result = await asyncFn();
  const duration = (new Date() as any) - startTime;
  console.debug(`logTiming: ${asyncFnName}: took ${duration} ms`);
  return result;
}

export function isValidHttpsUrl(url: string) {
  if (isEmpty(url)) return false;
  let urlObj = undefined;
  try {
    urlObj = new URL(url);
  } catch (err) {
    return false;
  }

  return urlObj.protocol === 'https:';
}

export function getSanitizedUrlsList(commaSeparatedUrls: string) {
  const urls = commaSeparatedUrls.split(',').map(url => url.trim());
  return uniq(urls.filter(url => isValidHttpsUrl(url)));
}

export type MessageValue = string | unknown;

export function toString(msg: MessageValue) {
  if (isString(msg)) {
    return msg;
  }
  return JSON.stringify(msg);
}

export class Queue<T> {
  private items: Array<T> = [];
  constructor(public limit: number) {}

  push(item: T) {
    if (this.items.length === this.limit) {
      this.items.pop();
    }
    this.items.push(item);
  }

  getItems() {
    return this.items;
  }

  getCurrentLimit() {
    return this.items.length;
  }
}

export const getSecInPretifiedMinute = (totalSec: number) => {
  totalSec = Math.floor(totalSec);
  return `${Math.floor(totalSec / 60)}:${totalSec % 60}`;
};

export const getReadableFileSize = (fileSizeInKb: number): string => {
  let i = -1;
  const unitName = [' Mbps', ' Gbps', ' Tbps', ' Pbps', ' Ebps', ' Zbps', ' Ybps'];
  do {
    fileSizeInKb = fileSizeInKb / 1024;
    i++;
  } while (fileSizeInKb > 1024);

  return Math.max(fileSizeInKb, 0.1).toFixed(2) + unitName[i];
};

export const getFileExtension = (fileName: string): string => {
  const parts = fileName.split('.');
  return parts.length > 1 ? (parts.pop() as string) : '';
};

export const splitNameAndExtension = (title: string): FileNameAndExtension => {
  const words = title.split('.');
  const wordsLength = words.length;
  const lastWord = words.pop() ?? '';
  const extension = wordsLength > 1 ? lastWord : '';
  const name = words.join('.');
  return { extension: extension, name: name };
};

export const convertBytesToGB = (bytes: number): number => {
  const bytesInOneGB = 1024 ** 3; // 1 GB = 1024^3 bytes
  return bytes / bytesInOneGB;
};
